<template>
  <Modal v-model="modal" sticky scrollable :mask-closable="true" width="60%" @on-cancel="handleCancel"
    class="custom-modal">
    <template #header class="custom-modal-header">
      <p class="title">提交数据目录清单</p>
      <div class="modal-close" @click="handleCancel">关闭 <span>ESC</span></div>
    </template>
    <div class="status">
      <div class="label">选择提交</div>
      <div class="staticInfo">
        重要数据目录
        <span class="link">{{ obj.important }}</span> 条，核心数据目录
        <span class="link">{{ obj.core }}</span> 条
      </div>
    </div>
    <div class="card">
      <h2>上传数据目录清单的盖章文件(PDF格式)</h2>
      <div class="upload-box" v-if="!isFile">
        <Upload multiple type="drag" :action="action" ref="upload" :max-size="51200" accept="pdf" :format="format"
          :headers="headers" :before-upload="handleUpload" :show-upload-list="false">
          <div class="upload-content">
            <img src="../../../assets/img/点击上传-默认.png" alt="" class="upload-logo" />
            <h2>点击上传</h2>
            <p>或者拖拽本地文件到此区域</p>
            <p class="orange">支持扩展名：pdf</p>
          </div>
        </Upload>
      </div>
      <div class="btn btn-default" @click="downTemplate" v-if="!isFile">
        <Icon type="md-arrow-down" />导出数据目录清单
      </div>
      <div class="file-box" v-if="isFile">
        <div class="file-box-header">
          <div class="logo">
            <img src="../../../assets/img/pdf.png" alt />
          </div>
          <div class="file-info">
            <p class="fileName" v-if="fileInfo.name">{{ fileInfo.name }}</p>
            <p class="dataSize" v-if="fileInfo.newSize">
              {{ fileInfo.newSize }}
            </p>
          </div>
        </div>
        <div class="action-box">
          <div class="btn btn-simple margin-right-10" @click="handleAgain">
            重新选择
          </div>
        </div>
      </div>
    </div>
    <div class="alertInfo" v-if="isFile">
      <p>
        <Icon type="ios-information-circle" />
        确定后无法修改，数据目录将提交至相关业务部门复核
      </p>
      <div class="btn btn-default" @click="handleConfirm">
        <i class="ivu-icon ivu-icon-ios-loading ivu-load-loop" v-if="loading"></i>
        确定
      </div>
    </div>
    <template #footer> </template>
  </Modal>
</template> 
  
  <script setup>
import { Modal, Message } from "view-ui-plus";
import { ref, inject, onMounted, onBeforeUnmount } from "vue";
import DownFiles from "@/utils/dowFile";
import qs from "qs";
import bus from "@/utils/bus";
let axios = inject("axios");
let modal = ref(false);
const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });
let action = process.env.VUE_APP_URL + `/content/uploadListFile`;
let format = ["pdf"];
let headers = ref({
  Authorization: "",
});

const handleCancel = () => {
  modal.value = false;
  currentFile.value = null;
  fileInfo.value = null;
  isFile.value = false;
};

let isFile = ref(false);
let fileInfo = ref({});
let currentFile = ref(null);

//上传之前

const handleUpload = (file) => {
  currentFile.value = file;
  fileInfo.value = {
    name: file.name,
    newSize: formatBytes(file.size),
  };
  isFile.value = true;

  const isPdf = file.type === "application/pdf";
  //限制文件上传类型
  if (!isPdf) {
    Message.error({
      background: true,
      content: "上传文件只能是pdf格式!",
      duration: 3,
    });
    currentFile.value = null;
    fileInfo.value = null;
    isFile.value = false;
    return false;
  }
  // return new Promise((resolve, reject) => {
  const is10M = file.size / 1024 / 1024 < 50;
  //限制文件上传大小
  if (!is10M) {
    Message.error({
      background: true,
      content: "上传文件大小不能超过 50MB!",
      duration: 3,
    });
    currentFile.value = null;
    fileInfo.value = null;
    isFile.value = false;
    return false;
  }

  return false;
  // });
};

//下载模板
const downTemplate = () => {
  let url = `/file/generateDcList`;
  let params = {
    ids: dataIds.value.join(","),
  };

  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let url = res.data.data.fileDownUrl;
      window.open(url);
    }
  });
};

//字节单位转换
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 B"; //Bytes
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      headers.value.Authorization = "Bearer " + user.auth_token;
    }
  }
};
//重新选择
const handleAgain = () => {
  fileInfo.value = null;
  currentFile.value = null;
  isFile.value = false;
};

let loading = ref(false);
// 确认
const handleConfirm = () => {
  if (loading.value == false) {
    let url = `/content/uploadListFile`;
    let params = {
      file: currentFile.value,
      ids: dataIds.value.join(","),
    };
    loading.value = true;
    axios
      .post(url, params, {
        headers: {
          "content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // modal.value = false;
        loading.value = false;
        bus.emit("searchData");
        bus.emit("getCount");
        if (res.data.code == 200) {
          Message.success("提交成功");
          handleAgain()
          modal.value = false;
        } else {
          let obj = {
            title: res.data.message,
            data: res.data.data,
          };
          bus.emit("errorData", obj);
        }
      });
  }

};

let obj = ref({
  important: 0,
  core: 0,
});
let dataIds = ref([]);
//处理重要数据条数、核心数据条数、选中文件id
const dealData = (data) => {
  // console.log(data)
  dataIds.value = data.map((item) => {
    return item.id;
  });
  obj.value.important = data.filter((item) => item.level == "重要数据").length;
  obj.value.core = data.filter((item) => item.level == "核心数据").length;
};

onMounted(() => {
  getUserInfo();
  bus.on("selectArr", (data) => {
    dealData(data);
  });
});
</script>
  
  <style lang="scss" scoped>
@import "./modal.scss";
</style>